import React, { useEffect, useState } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Title from "./muiTitle"

const MyAPITable = () => {
  const [tableData, setTableData] = useState([])
  const apiUrl = "https://eciszko9ej.execute-api.us-west-2.amazonaws.com/prod/stock_analysis_table_api"

  useEffect(() => {
    // Fetch data from the AWS API endpoint using fetch

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        return response.json() // Parse the response body as JSON
      })
      .then((data) => {
        // The API response is now parsed into an array of objects
        const parsedData = JSON.parse(data.body)
        setTableData(parsedData)
      })
      .catch((error) => {
        console.error("Error fetching data:", error)
        setTableData([]) // Set empty array or handle error state accordingly
      })
  }, [])

  return (
    <React.Fragment>
      <Title>Share Prices</Title>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Company</TableCell>
            <TableCell>Market Cap</TableCell>
            <TableCell>Market Cap Per Employee</TableCell>
            <TableCell>Revenue</TableCell>
            <TableCell>Revenue Per Employee</TableCell>
            <TableCell>Earnings</TableCell>
            <TableCell>Earnings Per Employee</TableCell>
            <TableCell>Employees</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row) => (
            <TableRow key={row.company}>
              <TableCell>{row.company}</TableCell>
              <TableCell>{row.market_cap}</TableCell>
              <TableCell>{row.market_cap_per_employee}</TableCell>
              <TableCell>{row.revenue}</TableCell>
              <TableCell>{row.revenue_per_employee}</TableCell>
              <TableCell>{row.earnings}</TableCell>
              <TableCell>{row.earnings_per_employee}</TableCell>
              <TableCell>{row.employees}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  )
}

export default MyAPITable
