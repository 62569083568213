import React from "react";

import Dashboard from "./components/muiDashboard";

/**
 * The App component renders the TodoList component
 * and displays a title "My Todo List"
 *
 * @returns {JSX.Element}
 */
function App() {

  return (
    <Dashboard />
  );
}

export default App;
